<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">書籍詳細內容</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section
      class="main notice digital-card"
      style="
        padding: 15px !important;
        padding-top: 90px !important;
        background-color: #fff;
      "
    >
      <div class="flexV width gap-1">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          >書名
        </span>
        <span class="font-normal cNeutra500 fL" style="padding-bottom: 10px">{{
          detail.book_title
        }}</span>
      </div>
      <hr style="border-top: solid 1px #d8d8d8; width: 100%" />
      <div class="flexV width gap-1">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          >作者
        </span>
        <span class="font-normal cNeutra500 fL" style="padding-bottom: 10px">{{
          detail.book_author
        }}</span>
      </div>
      <hr style="border-top: solid 1px #d8d8d8; width: 100%" />
      <div class="flexV width gap-1" v-if="detailType == 'history'">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          v-if="detailType == 'history'"
          >借書日期
        </span>
        <span
          class="font-normal cNeutra500 fL"
          style="padding-bottom: 10px"
          v-if="detailType == 'history'"
          >{{ detail.book_lend_date }}</span
        >
      </div>
      <hr
        style="border-top: solid 1px #d8d8d8; width: 100%"
        v-if="detailType == 'history'"
      />
      <div class="flexV width gap-1" v-if="detailType == 'history'">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          v-if="detailType == 'history'"
          >歸還日期
        </span>
        <span
          class="font-normal cNeutra500 fL"
          style="padding-bottom: 10px"
          v-if="detailType == 'history'"
          >{{ detail.book_return_date }}</span
        >
      </div>
      <hr
        style="border-top: solid 1px #d8d8d8; width: 100%"
        v-if="detailType == 'history'"
      />
      <div class="flexV width gap-1" v-if="detailType == 'readbook'">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          v-if="detailType == 'readbook'"
          >預約日期
        </span>
        <span
          class="font-normal cNeutra500 fL"
          style="padding-bottom: 10px"
          v-if="detailType == 'readbook'"
          >{{ detail.book_date }}</span
        >
      </div>
      <hr
        style="border-top: solid 1px #d8d8d8; width: 100%"
        v-if="detailType == 'readbook'"
      />
      <div class="flexV width gap-1" v-if="detailType == 'readbook'">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          v-if="detailType == 'readbook'"
          >預約順序
        </span>
        <span
          class="font-normal cNeutra500 fL"
          style="padding-bottom: 10px"
          v-if="detailType == 'readbook'"
          >{{ detail.book_order }}</span
        >
      </div>
      <hr
        style="border-top: solid 1px #d8d8d8; width: 100%"
        v-if="detailType == 'readbook'"
      />

      <div
        class="flexV width gap-1"
        v-if="detail.book_isbn !== '' && detail.book_isbn !== null"
      >
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          >ISBN
        </span>
        <span class="font-normal cNeutra500 fL" style="padding-bottom: 10px">{{
          detail.book_isbn
        }}</span>
      </div>
      <hr
        style="border-top: solid 1px #d8d8d8; width: 100%"
        v-if="detail.book_isbn !== '' && detail.book_isbn !== null"
      />
      <div class="flexV width gap-1">
        <span
          class="fS font-normal cNeutral900 font-lg fz-100"
          style="padding-top: 10px"
          >條碼號
        </span>
        <span class="font-normal cNeutra500 fL" style="padding-bottom: 10px">{{
          detail.book_barcode
        }}</span>
      </div>
      <hr style="border-top: solid 1px #d8d8d8; width: 100%" />
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      detail: {},
      detailType: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    if (this.$route.query.detail !== undefined) {
      this.detailType = this.$route.query.detail;
    }

    this.getDigitalCardBookDetail();

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },

    async getDigitalCardBookDetail() {
      if (this.$route.query.readercode !== undefined) {
        const url = `${this.apiHost}/digital_card/detail/v1/merchants/${this.merchantId}/detail?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&readercode=${this.$route.query.readercode}&barcode=${this.$route.query.barcode}`;

        this.detail = await axios({
          url,
          method: "GET",
        }).then(function (response) {
          return response.data.data[0];
        });

        console.log(this.detail);
      }
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}

.digital-card .tab.active {
  border-bottom: 2px solid var(--c1);
}

.digital-card .tab {
  border-bottom: 2px solid #fff;
}
</style>
