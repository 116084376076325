var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body mobile"},[_c('section',{staticClass:"header notice"},[_c('div',{staticClass:"flexH width"},[_c('a',{staticClass:"icon iconButton third",on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left","size":"lg"}})],1)]),_c('span',{staticClass:"fL nowrap font-bold font-white"},[_vm._v("借閱清單")]),_c('div',{staticClass:"flexH width right"})]),_c('section',{staticClass:"main notice digital-card",staticStyle:{"padding":"60px 0 !important"}},[_c('div',{staticClass:"filter flexH width bg-white justify-between",staticStyle:{"width":"100%","margin":"0","padding-top":"15px"}},[_c('div',{staticClass:"tabs line fM rounded"},[_c('a',{staticClass:"tab",class:_vm.status === 0 ? 'active' : '',staticStyle:{"padding":"8px 0","display":"inline-block","text-align":"center"},on:{"click":function($event){_vm.status = 0}}},[_c('span',{staticClass:"fM"},[_vm._v("目前借閱清單")])]),_c('a',{staticClass:"tab",class:_vm.status === 1 ? 'active' : '',staticStyle:{"padding":"8px 0","display":"inline-block","text-align":"center"},on:{"click":function($event){_vm.status = 1}}},[_c('span',{staticClass:"fM"},[_vm._v("借閱歷史清單")])])])]),(
        _vm.currentList != undefined &&
        Object.keys(_vm.currentList).length > 0 &&
        _vm.status === 0
      )?_c('div',{staticClass:"records flexV width padding",staticStyle:{"padding-top":"1.5rem"}},_vm._l((_vm.currentList),function(item,index){return _c('div',{key:'item' + index},[_c('RouterLink',{staticClass:"record flexH width margin relative",attrs:{"to":'/digital-card/detail?readercode=' +
            _vm.$route.query.readercode +
            '&barcode=' +
            item.Barcode +
            '&detail=lend'}},[_c('div',{staticClass:"flexV width gap-1"},[_c('span',{staticClass:"fS font-normal cNeutral900 font-lg fz-100"},[_vm._v(_vm._s(item.title)+" ")]),_c('span',{staticClass:"font-normal cNeutra500 fL"},[_vm._v(_vm._s(item.Title))]),_c('span',{staticClass:"fS font-normal cNeutra500 font-sm flex items-center",style:(item.status === 0 ? 'color: var(--k1)' : '正在使用中')},[_vm._v(" 還書日期 "),_c('span',{staticClass:"primary",staticStyle:{"margin-left":"4px"}},[_vm._v(_vm._s(item.Returndate))])])]),_c('div',{staticClass:"primary"},[_c('font-awesome-icon',{attrs:{"icon":"fa fa-chevron-right","size":"lg"}})],1)]),_c('div',{staticClass:"lineH"})],1)}),0):_vm._e(),(
        (_vm.currentList != undefined &&
          Object.keys(_vm.currentList).length == 0 &&
          _vm.status === 0) ||
        (_vm.currentList == undefined && _vm.status === 0)
      )?_c('div',{staticClass:"text-center gap-6 item-center py-4"},[_c('img',{staticClass:"mb-3",staticStyle:{"width":"160px","height":"160px !important","margin-top":"0.75rem"},attrs:{"src":require("@/assets/icon/null-digitalcard-list.svg")}}),_c('div',{staticClass:"fL font-medium cNeutral500 text-center"},[_vm._v(" 很抱歉，目前尚未有借閱紀錄 ")])]):_vm._e(),(
        _vm.historyList != undefined &&
        Object.keys(_vm.historyList).length > 0 &&
        _vm.status === 1
      )?_c('div',{staticClass:"records flexV width padding",staticStyle:{"padding-top":"1.5rem"}},_vm._l((_vm.historyList),function(item,index){return _c('div',{key:'item' + index},[_c('RouterLink',{staticClass:"record flexH width margin relative",attrs:{"to":'/digital-card/detail?readercode=' +
            _vm.$route.query.readercode +
            '&barcode=' +
            item.Barcode +
            '&detail=history'}},[_c('div',{staticClass:"flexV width gap-1"},[_c('span',{staticClass:"fS font-normal cNeutral900 font-lg fz-100"},[_vm._v("書名 ")]),_c('span',{staticClass:"font-normal cNeutra500 fL"},[_vm._v(_vm._s(item.Title))])]),_c('div',{staticClass:"primary"},[_c('font-awesome-icon',{attrs:{"icon":"fa fa-chevron-right","size":"lg"}})],1)]),_c('div',{staticClass:"lineH"})],1)}),0):_vm._e(),(
        (_vm.historyList != undefined &&
          Object.keys(_vm.historyList).length == 0 &&
          _vm.status === 1) ||
        (_vm.historyList == undefined && _vm.status === 1)
      )?_c('div',{staticClass:"text-center gap-6 item-center py-4"},[_c('img',{staticClass:"mb-3",staticStyle:{"width":"160px","height":"160px !important","margin-top":"0.75rem"},attrs:{"src":require("@/assets/icon/null-digitalcard-list.svg")}}),_c('div',{staticClass:"fL font-medium cNeutral500 text-center"},[_vm._v(" 很抱歉，目前尚未有借閱紀錄 ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }