<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">新增數位圖書證</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">國籍身分</span>

            <select
              v-model="userData.type"
              style="
                width: calc(100% - 5px);
                background-color: #fff !important;
                color: #000 !important;
              "
            >
              <option value="1" :selected="userData.type == 1">
                中華民國國民
              </option>
              <option value="2" :selected="userData.type == 2">
                新（移）住民
              </option>
            </select>
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              身分證字號
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.idno"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              姓名
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.name"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon
                icon="fa-solid fa-envelope"
              ></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              借書證號
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.cardId"
            />
          </div>
        </div>

        <DigitalCardAlert
          :open="popupObj.open"
          :title="popupObj.title"
          :bottomTitle="popupObj.buttomTitle"
          :readercode="popupObj.readercode"
          @popupOpen="setPopupObj"
        >
        </DigitalCardAlert>
      </div>

      <div class="records flexV width padding mb-3 px-2">
        若無實證圖書證，請先至圖書館辦理實體卡，才可申請數位圖書證綁定。
      </div>

      <div class="buttons records flexV width padding">
        <div v-if="status === 0">
          <button @click="submit()">確認送出</button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import DigitalCardAlert from "../../components/DigitalCardAlert.vue";
export default {
  components: {
    DigitalCardAlert,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      latestReadAt: 0,
      status: 0,
      userData: {
        type: 1,
        idno: "",
        name: "",
        cardId: "",
      },
      popupObj: {
        open: false,
        buttomTitle: "",
        title: "111",
        readercode: "",
      },
      cardValid: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    if (this.user) {
      this.userData.idno = this.user.user.idno;
      this.userData.name = this.user.user.name;
    }
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
    async submit() {
      if (this.userData.idno == "" || this.userData.idno == null) {
        this.popupObj.title = "請填寫身份證字號";
        this.popupObj.open = true;
      } else if (this.userData.name == "" || this.userData.name == null) {
        this.popupObj.title = "請填寫姓名";
        this.popupObj.open = true;
      } else if (this.userData.name.length > 8) {
        this.popupObj.title = "填寫姓名過長";
        this.popupObj.open = true;
      } else if (this.userData.cardId == "" || this.userData.cardId == null) {
        this.popupObj.title = "請填寫借書證號";
        this.popupObj.open = true;
      } else {
        let url = `${this.apiHost}/digital_card/card_valid/v1/merchants/${this.merchantId}/card_valid?idno=${this.userData.idno}&readercode=${this.userData.cardId}`;
        let cardValid = await axios({
          url,
          method: "GET",
        }).then(function (res) {
          return res;
        });
        if (!cardValid.data.result) {
          this.popupObj.title = cardValid.data.data.error_message;
          this.popupObj.open = true;
        }
        if (cardValid.data.result) {
          let url = `${this.apiHost}/digital_card/post_card_no/v1/merchants/${this.merchantId}/post_card_no`;

          let submitRes = await axios
            .post(url, {
              merchant_id: this.merchantId,
              idno: this.userData.idno,
              card_no: this.userData.cardId,
              sso_uid: this.user.userId,
              member_id: this.userInfo.memberId,
              name: this.userData.name,
              type: this.userData.type,
            })
            .then(function (res) {
              return res;
            });

          if (submitRes.data.result) {
            this.$router.push({ path: "/digital-card" });
          } else {
            this.popupObj.title = submitRes.data.message;
            this.popupObj.open = true;
          }
        }
      }
    },
    setPopupObj(val) {
      this.popupObj.open = val;
    },
  },
};
</script>

<style>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

.label {
  width: 170px;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
