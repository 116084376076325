<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">借閱清單</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section
      class="main notice digital-card"
      style="padding: 60px 0 !important"
    >
      <div
        class="filter flexH width bg-white justify-between"
        style="width: 100%; margin: 0; padding-top: 15px"
      >
        <div class="tabs line fM rounded">
          <a
            class="tab"
            :class="status === 0 ? 'active' : ''"
            @click="status = 0"
            style="padding: 8px 0; display: inline-block; text-align: center"
          >
            <span class="fM">目前借閱清單</span>
          </a>

          <a
            class="tab"
            :class="status === 1 ? 'active' : ''"
            @click="status = 1"
            style="padding: 8px 0; display: inline-block; text-align: center"
          >
            <span class="fM">借閱歷史清單</span>
          </a>
        </div>
      </div>

      <!-- 目前借閱清單 紀錄 -->
      <div
        v-if="
          currentList != undefined &&
          Object.keys(currentList).length > 0 &&
          status === 0
        "
        class="records flexV width padding"
        style="padding-top: 1.5rem"
      >
        <div v-for="(item, index) in currentList" :key="'item' + index">
          <RouterLink
            class="record flexH width margin relative"
            :to="
              '/digital-card/detail?readercode=' +
              $route.query.readercode +
              '&barcode=' +
              item.Barcode +
              '&detail=lend'
            "
          >
            <div class="flexV width gap-1">
              <span class="fS font-normal cNeutral900 font-lg fz-100"
                >{{ item.title }}
              </span>
              <span class="font-normal cNeutra500 fL">{{ item.Title }}</span>
              <span
                class="fS font-normal cNeutra500 font-sm flex items-center"
                :style="item.status === 0 ? 'color: var(--k1)' : '正在使用中'"
              >
                還書日期
                <span class="primary" style="margin-left: 4px">{{
                  item.Returndate
                }}</span>
              </span>
            </div>

            <div class="primary">
              <font-awesome-icon
                icon="fa fa-chevron-right"
                size="lg"
              ></font-awesome-icon>
            </div>
          </RouterLink>
          <div class="lineH"></div>
        </div>
      </div>

      <div
        v-if="
          (currentList != undefined &&
            Object.keys(currentList).length == 0 &&
            status === 0) ||
          (currentList == undefined && status === 0)
        "
        class="text-center gap-6 item-center py-4"
      >
        <img
          style="width: 160px; height: 160px !important; margin-top: 0.75rem"
          class="mb-3"
          src="@/assets/icon/null-digitalcard-list.svg"
        />
        <div class="fL font-medium cNeutral500 text-center">
          很抱歉，目前尚未有借閱紀錄
        </div>
      </div>

      <!-- 目前借閱清單結束 -->

      <!-- 借閱歷史清單 -->
      <div
        v-if="
          historyList != undefined &&
          Object.keys(historyList).length > 0 &&
          status === 1
        "
        class="records flexV width padding"
        style="padding-top: 1.5rem"
      >
        <div v-for="(item, index) in historyList" :key="'item' + index">
          <RouterLink
            class="record flexH width margin relative"
            :to="
              '/digital-card/detail?readercode=' +
              $route.query.readercode +
              '&barcode=' +
              item.Barcode +
              '&detail=history'
            "
          >
            <div class="flexV width gap-1">
              <span class="fS font-normal cNeutral900 font-lg fz-100"
                >書名
              </span>
              <span class="font-normal cNeutra500 fL">{{ item.Title }}</span>
              <!-- <span
                class="fS font-normal cNeutra500 font-sm flex items-center"
                :style="item.status === 0 ? 'color: var(--k1)' : '正在使用中'"
              >
                還書日期
                <span class="primary" style="margin-left: 4px">{{
                  item.date
                }}</span>
              </span> -->
            </div>

            <div class="primary">
              <font-awesome-icon
                icon="fa fa-chevron-right"
                size="lg"
              ></font-awesome-icon>
            </div>
          </RouterLink>
          <div class="lineH"></div>
        </div>
      </div>
      <div
        v-if="
          (historyList != undefined &&
            Object.keys(historyList).length == 0 &&
            status === 1) ||
          (historyList == undefined && status === 1)
        "
        class="text-center gap-6 item-center py-4"
      >
        <img
          style="width: 160px; height: 160px !important; margin-top: 0.75rem"
          class="mb-3"
          src="@/assets/icon/null-digitalcard-list.svg"
        />
        <div class="fL font-medium cNeutral500 text-center">
          很抱歉，目前尚未有借閱紀錄
        </div>
      </div>
      <!-- 借閱歷史清單結束 -->
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      latestReadAt: 0,
      status: 0,
      historyList: {},
      currentList: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    this.getDigitalCardLendHistoryList();
    this.getDigitalCardLendList();

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    detail(item) {
      console.log(item);
      // this.$router.push(`/parking-coupons/confirm/${item.id}`);
    },
    detail2(item) {
      console.log(item);
      // this.$router.push(`/parking-coupons/detail/${item.id}`);
    },
    async getDigitalCardLendHistoryList() {
      if (this.$route.query.readercode !== undefined) {
        const url = `${this.apiHost}/digital_card/lend_history_list/v1/merchants/${this.merchantId}/lend_history_list?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&readercode=${this.$route.query.readercode}`;

        this.historyList = await axios({
          url,
          method: "GET",
        }).then(function (response) {
          if (response.data.data.Record !== undefined)
            if (response.data.data.Record.Title !== undefined)
              return [response.data.data.Record];
          return response.data.data.Record;
        });
      }
    },

    async getDigitalCardLendList() {
      if (this.$route.query.readercode !== undefined) {
        const url = `${this.apiHost}/digital_card/lend_list/v1/merchants/${this.merchantId}/lend_list?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&readercode=${this.$route.query.readercode}`;

        this.currentList = await axios({
          url,
          method: "GET",
        }).then(function (response) {
          if (response.data.data.Record !== undefined)
            if (response.data.data.Record.Title !== undefined)
              return [response.data.data.Record];
          return response.data.data.Record;
        });
      }
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}

.digital-card .tab.active {
  border-bottom: 2px solid var(--c1);
}

.digital-card .tab {
  border-bottom: 2px solid #fff;
}
</style>
