var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body mobile"},[_c('section',{staticClass:"header notice"},[_c('div',{staticClass:"flexH width"},[_c('a',{staticClass:"icon iconButton third",on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left","size":"lg"}})],1)]),_c('span',{staticClass:"fL nowrap font-bold font-white"},[_vm._v("切換數位圖書證")]),_c('div',{staticClass:"flexH width right"})]),_c('section',{staticClass:"main notice"},[_c('div',{staticClass:"records flexV width padding"},_vm._l((_vm.datas),function(item,index){return _c('div',{key:'item' + index},[_c('a',{staticClass:"record flexH width margin relative shadow-md"},[_c('div',{staticClass:"flexV width gap-1",on:{"click":function($event){return _vm.setActive(item)}}},[_c('span',{staticClass:"fL font-normal cNeutral900 font-lg fz-100"},[_vm._v(_vm._s(item.name)+" ")]),_c('span',{staticClass:"font-normal cNeutra500"},[_vm._v(_vm._s(item.card_no)+" "),(
                  _vm.currentActive !== undefined &&
                  _vm.currentActive.length > 0 &&
                  item.id === _vm.currentActive[0].id
                )?_c('span',{staticStyle:{"font-size":"0.65rem","background-color":"#ffc303","color":"#000","padding":"3px 10px","margin-left":"5px","border-radius":"20px"}},[_vm._v(" 任務中 ")]):_vm._e()]),_c('span',{staticClass:"fS font-normal cNeutra500 font-sm flex items-center",style:(_vm.currentActive !== undefined &&
                _vm.currentActive.length > 0 &&
                item.id === _vm.currentActive[0].id
                  ? 'color: var(--k1)'
                  : '正在使用中')},[_vm._v(" "+_vm._s(_vm.currentActive !== undefined && _vm.currentActive.length > 0 && item.id === _vm.currentActive[0].id ? "正在使用中" : "使用此圖書證")+" ")])]),_c('div',{},[(
                _vm.currentActive !== undefined &&
                _vm.currentActive.length > 0 &&
                item.id !== _vm.currentActive[0].id
              )?_c('img',{attrs:{"src":require("@/assets/icon/delete.svg"),"alt":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}}):_vm._e(),(
                _vm.currentActive !== undefined &&
                _vm.currentActive.length > 0 &&
                item.id === _vm.currentActive[0].id
              )?_c('img',{attrs:{"src":require("@/assets/icon/delete-gray.svg"),"alt":""}}):_vm._e()])]),_c('div',{staticClass:"lineH"})])}),0),_c('div',{staticClass:"records flexV width padding mb-3 px-2 text-center fM"},[_vm._v(" 至多可以有 8 張圖書證 ")]),_c('div',{staticClass:"buttons records flexV width padding"},[_c('div',[_c('button',{on:{"click":function($event){return _vm.add()}}},[_vm._v("新增圖書證")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }