var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body mobile"},[_c('section',{staticClass:"header notice"},[_c('div',{staticClass:"flexH width"},[_c('a',{staticClass:"icon iconButton third",on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left","size":"lg"}})],1)]),_c('span',{staticClass:"fL nowrap font-bold font-white"},[_vm._v("線上預約查詢")]),_c('div',{staticClass:"flexH width right"})]),_c('section',{staticClass:"main notice"},[(
        _vm.currentList != undefined &&
        Object.keys(_vm.currentList).length > 0 &&
        _vm.status === 0 &&
        _vm.status === 0
      )?_c('div',{staticClass:"records flexV width padding mt-3"},_vm._l((_vm.currentList),function(item,index){return _c('div',{key:'item' + index},[_c('RouterLink',{staticClass:"record flexH width margin relative",attrs:{"to":'/digital-card/detail?readercode=' +
            _vm.$route.query.readercode +
            '&barcode=' +
            item.Barcode +
            '&detail=readbook'}},[_c('div',{staticClass:"flexV width gap-1"},[_c('span',{staticClass:"fS font-normal cNeutral900 font-lg fz-100"},[_vm._v("書名 ")]),_c('span',{staticClass:"font-normal cNeutra500 fL"},[_vm._v(_vm._s(item.Title))]),_c('span',{staticClass:"font-normal cNeutra500 fS"},[_vm._v("預約順位 "+_vm._s(item.bookorder))])]),_c('div',{staticClass:"primary"},[_c('font-awesome-icon',{attrs:{"icon":"fa fa-chevron-right","size":"lg"}})],1)]),_c('div',{staticClass:"lineH"})],1)}),0):_vm._e(),(
        (_vm.currentList != undefined &&
          Object.keys(_vm.currentList).length == 0 &&
          _vm.status === 0) ||
        _vm.currentList == undefined
      )?_c('div',{staticClass:"text-center gap-6 item-center py-4"},[_c('img',{staticClass:"mb-3",staticStyle:{"width":"160px","height":"160px !important","margin-top":"0.75rem"},attrs:{"src":require("@/assets/icon/null-digitalcard-list.svg")}}),_c('div',{staticClass:"fL font-medium cNeutral500 text-center"},[_vm._v(" 很抱歉，目前尚未有線上預約紀錄 ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }