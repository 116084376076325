<template>
  <div class="body fixed mobile" v-show="open">
    <section class="popNoQA popup" ref="popup">
      <div
        class="popBody dc-popBody"
        style="background-color: #fff !important; padding: 20px 0 0 0"
      >
        <section class=""></section>
        <section class="popMain">
          <a class="close" @click="setEmit('popupOpen', false)">
            <font-awesome-icon
              icon="fas fa-times"
              style="color: #000"
            ></font-awesome-icon>
          </a>
          <div class="fL">{{ title }}</div>

          <div class="fL">{{ bottomTitle }}</div>
        </section>
        <section class="popFooter">
          <button class="dc-button" @click="setEmit('popupOpen', false)">
            確定
          </button>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Popup",
  props: ["open", "title", "bottomTitle", "readercode"],
  data() {
    return {
      qrcode: {},
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
    };
  },
  created() {},
  mounted() {
    // this.$el.addEventListener('click', this.onClick)

    this.popup();
  },
  methods: {
    setEmit(eventName, val) {
      this.$emit(eventName, val);
    },
    popup() {
      let target = this.$refs["popup"];
      target.style.display = "flex";
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>

<style>
.dc-popBody {
  height: unset !important;
}

.dc-popMain {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 30px !important;
}

.dc-popMain .fL {
  text-align: center;
}

.dc-popFooter {
  justify-content: center !important;
  margin: 10px !important;
}

.dc-close-btn {
  position: relative !important;
  top: unset;
  right: unset;
  background-color: #fff !important;
  color: #000 !important;
  padding: 10px !important;
}

.dc-button {
  font-size: var(--fM);
  font-family: "Noto Sans TC", "Roboto", "Arial", "微軟正黑體";
  color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  background-color: var(--k1);
  border: 0;
}
</style>
